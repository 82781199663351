import { createSlice } from "@reduxjs/toolkit";

const LetralHiringSlice = createSlice({
    name: "letralHiring",
    initialState: {
        letralHiringJobsData: [],
    },
    reducers: {
        setLetralHiringJobsData: (state, action) => {
            state.letralHiringJobsData = action.payload;
        },
    },
});

// Export Actions
export const { setLetralHiringJobsData } = LetralHiringSlice.actions;

// Export Reducer
export default LetralHiringSlice.reducer;