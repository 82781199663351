import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RoutesHead from "./routes";
import OnlineStatusProvider from "./components/reusablecomponents/CheckOnline";

function App() {
  return (
    <div>
      {/* <OnlineStatusProvider>
        <RoutesHead />
      </OnlineStatusProvider> */}

      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <h1
          className="text-center"
          style={{ fontSize: "3rem", color: "#007bff" }}
        >
          Coming Soon
        </h1>
        <p
          className="text-center"
          style={{ fontSize: "1.5rem", color: "#dc3545" }}
        >
          Website Under Maintenance
        </p>
      </div>
    </div>
  );
}

export default App;
